<template>
  <layout-dashboard>
    <router-view />
  </layout-dashboard>
</template>

<script>
import LayoutDashboard from "@core/layouts/layout-vertical/LayoutDashboard.vue";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    LayoutDashboard,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    };
  },
};
</script>
